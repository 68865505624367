import React, { useEffect } from "react";
import Layout from "../components/Layout";
import Images from "../utils/images";
import { KRoutes } from "../routers/router";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_CONTENTS } from "../core/constants";
import { useAppSelector } from "../redux/hooks";

const {
  DASHBOARD_TITLE_ENGLISH,
  DASHBOARD_TITLE_TAMIL,
  DASHBOARD_DESCRIPTION_ENGLISH,
  DASHBOARD_DESCRIPTION_TAMIL,
  DASHBOARD_BUTTON_ENGLISH,
  DASHBOARD_BUTTON_TAMIL,
} = DASHBOARD_CONTENTS;

const ScreenDashboard = () => {
  const navigate = useNavigate();
  const { selectedLanguage } = useAppSelector((state) => state.settings);

  return (
    <Layout title="dashboard">
      <div className="flex w-full h-full min-h-[80vh] justify-center items-center">
        <div className="hidden lg:flex w-1/2 items-center justify-center relative">
          <img alt="image" src={Images.dashboard} width={600} height={600} />
        </div>
        <div className="lg:flex lg:w-1/2 lg:items-center lg:justify-center lg:relative">
          <div className="w-full flex justify-center items-center my-10">
            <div className="px-6 md:px-0 md:py-12 md:w-[80%] lg:w-[80%] xl:w-[60%] flex flex-col justify-evenly items-center md:border rounded-3xl md:shadow-xl ">
              <p
                className={`text-2xl lg:text-3xl md:text-4xl font-extrabold uppercase text-primary-color pb-6 ${
                  selectedLanguage === "TAMIL" && "w-5/6 md:w-3/4 lg:w-2/3"
                }`}
              >
                {selectedLanguage === "TAMIL"
                  ? DASHBOARD_TITLE_TAMIL
                  : DASHBOARD_TITLE_ENGLISH}
              </p>
              <div className="lg:hidden w-1/2 items-center justify-center relative pb-6">
                <img
                  alt="image"
                  src={Images.dashboard}
                  width={600}
                  height={600}
                />
              </div>
              <p className="w-5/6 md:w-3/4 lg:w-2/3 text-justify pb-4">
                {selectedLanguage === "TAMIL"
                  ? DASHBOARD_DESCRIPTION_TAMIL
                  : DASHBOARD_DESCRIPTION_ENGLISH}
              </p>

              <div className="flex justify-center items-center w-full pt-4">
                <button
                  className="bg-secondary-color hover:bg-primary-color text-white font-bold py-3.5 lg:px-8 lg:py-3 rounded-xl w-1/2 text-xm md:text-sm xl:text-base "
                  onClick={() => navigate(KRoutes.query)}
                >
                  {selectedLanguage === "TAMIL"
                    ? DASHBOARD_BUTTON_TAMIL
                    : DASHBOARD_BUTTON_ENGLISH}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenDashboard;
