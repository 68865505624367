import React from "react";

const Loader = ({ height = "h-screen" }: { height?: string }) => {
  let circleCommonClasses = "h-2.5 w-2.5 bg-primary-color rounded-full";

  return (
    <div className={`bg-white/30 ${height} flex items-center justify-center`}>
      <div className={`${circleCommonClasses} mr-1 animate-bounce`}></div>
      <div className={`${circleCommonClasses} mr-1 animate-bounce200`}></div>
      <div className={`${circleCommonClasses} animate-bounce400`}></div>
    </div>
  );
};

export default Loader;
