import React from "react";
import validator from "validator";
import Layout from "../components/Layout";
import { useAppSelector } from "../redux/hooks";

const ScreenTermsAndConditions = () => {
  const { selectedLanguage } = useAppSelector((state) => state.settings);

  return (
    <Layout title="login">
      <div className="min-h-screen w-full py-10 px-10 lg:px-16 pb-16">
        <h1 className="text-4xl font-semibold pb-10 text-primary-color">
          {selectedLanguage === "TAMIL"
            ? "கால மற்றும் நிபந்தனைகள்"
            : "Term and Conditions"}
        </h1>
        <div className="prose">
          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL" ? "அறிமுகம்" : "Introduction"}:
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "கன்னியாகுமரி நகராட்சியின் இணையதளத்திற்கு வரவேற்கிறோம். இந்த இணையதளத்தை அணுகி பயன்படுத்துவதன் மூலம், இந்த விதிமுறைகள் மற்றும் நிபந்தனைகளுக்கு இணங்கவும், கட்டுப்படவும் ஒப்புக்கொள்கிறீர்கள்."
                : "Welcome to the Kanyakumari Municipality Corporation website. By accessing and using this website, you agree to comply with and be bound by these terms and conditions."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL"
              ? "பயனர் பொறுப்புகள்"
              : "User Responsibilities"}
            :
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "பயனர்கள் அனைத்து பொருந்தக்கூடிய சட்டங்கள் மற்றும் ஒழுங்குமுறைகளுக்கு இணங்க வேண்டும்."
                : "Users must comply with all applicable laws and regulations."}
            </li>
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "வலைத்தளத்தின் பொறுப்பான மற்றும் நெறிமுறையான பயன்பாடு எதிர்பார்க்கப்படுகிறது."
                : "Responsible and ethical use of the website is expected."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL"
              ? "உள்ளடக்க பயன்பாடு"
              : "Content Usage"}
            :
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "இந்த இணையதளத்தில் உள்ள அனைத்து உள்ளடக்கமும் பதிப்புரிமை மற்றும் அறிவுசார் சொத்துரிமை சட்டங்களால் பாதுகாக்கப்படுகிறது."
                : "All content on this website is protected by copyright and intellectual property laws."}
            </li>
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "உள்ளடக்கத்தை அங்கீகரிக்காமல் பயன்படுத்துவது கண்டிப்பாகத் தடைசெய்யப்பட்டுள்ளது."
                : "Unauthorized use of content is strictly prohibited."}
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenTermsAndConditions;
