import React, { MouseEventHandler, useEffect, useState } from "react";
import Layout from "../components/Layout";
import ImageViewer from "react-simple-image-viewer";
import Images from "../utils/images";
import { KRoutes } from "../routers/router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import Cropper, { Area } from "react-easy-crop";
import "react-html5-camera-photo/build/css/index.css";
import getCroppedImg from "../utils/cropImage";
import { useAppSelector } from "../redux/hooks";
import { getBranches, submitQuery } from "../services";
import { QUERY_CONTENTS, autoRefreshDelay } from "../core/constants";

const {
  QUERY_TITLE_ENGLISH,
  QUERY_TITLE_TAMIL,
  QUERY_DESCRIPTION_ENGLISH,
  QUERY_DESCRIPTION_TAMIL,
  QUERY_IMAGES_ENGLISH,
  QUERY_IMAGES_TAMIL,
  QUERY_OPEN_CAMERA_ENGLISH,
  QUERY_OPEN_CAMERA_TAMIL,
  QUERY_BRANCH_ENGLISH,
  QUERY_BRANCH_TAMIL,
  QUERY_NOTE_ENGLISH,
  QUERY_NOTE_TAMIL,
  QUERY_CANCEL_ENGLISH,
  QUERY_CANCEL_TAMIL,
  QUERY_SUBMIT_ENGLISH,
  QUERY_SUBMIT_TAMIL,
} = QUERY_CONTENTS;

const ScreenQuery = () => {
  const { branches, isLoggedIn, isLoading } = useAppSelector(
    (state) => state.user
  );
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  const [openCamera, setOpenCamera] = useState(false);
  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [viewedImageIndex, setViewedImageIndex] = useState<number>(0);
  const [isCropperOpen, setCropperOpen] = useState(false);
  const [cropImageIndex, setCropImageIndex] = useState<number>(0);
  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [branch, setBranch] = useState<string>();
  const [note, setNote] = useState<string>();
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    getBranches();
    const refreshInterval = setInterval(
      () => getBranches(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  useEffect(() => {
    // Get the user's current location (latitude and longitude)
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error: GeolocationPositionError) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              toast.warn(
                selectedLanguage === "TAMIL"
                  ? "புவிஇருப்பிடத்திற்கான கோரிக்கையை பயனர் நிராகரித்தார்."
                  : "User denied the request for Geolocation."
              );
              break;
            case error.POSITION_UNAVAILABLE:
              toast.warn(
                selectedLanguage === "TAMIL"
                  ? "இருப்பிடத் தகவல் கிடைக்கவில்லை."
                  : "Location information is unavailable."
              );
              break;
            case error.TIMEOUT:
              toast.warn(
                selectedLanguage === "TAMIL"
                  ? "பயனர் இருப்பிடத்தைப் பெறுவதற்கான கோரிக்கை காலாவதியானது."
                  : "The request to get user location timed out."
              );
              break;
            default:
              toast.warn(
                selectedLanguage === "TAMIL"
                  ? "புவி இருப்பிடம் முடக்கப்பட்டது! பின்னர் மீண்டும் முயற்சிக்கவும்."
                  : "Geo Location disabled! Try again later"
              );
          }
        }
      );
    } else {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "புவி இருப்பிடம் முடக்கப்பட்டது! பின்னர் மீண்டும் முயற்சிக்கவும்."
          : "Geo Location disabled! Try again later"
      );
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // You can adjust the breakpoint based on your needs
      const isMobileDevice = screenWidth <= 768;

      setIsMobile(isMobileDevice);
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleOpenCamera = () => {
    if (capturedImages.length < 3) {
      setOpenCamera(true);
    } else {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "மூன்று படங்கள் மட்டுமே அனுமதிக்கப்படுகின்றன"
          : "Only three image allowed"
      );
    }
  };
  const handleCrop = async (status: "crop" | "close") => {
    if (croppedAreaPixels && status === "crop") {
      const croppedImage = await getCroppedImg(
        capturedImages[cropImageIndex],
        croppedAreaPixels,
        0,
        { horizontal: false, vertical: false } // Flip (if any)
      );
      if (croppedImage) {
        let updatedCapturedImages = [...capturedImages];
        updatedCapturedImages[cropImageIndex] = croppedImage;
        setCapturedImages(updatedCapturedImages);
      }
    }
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCropperOpen(false);
  };

  const navigate = useNavigate();

  const dataURItoBlob = (dataURI: string): Blob => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!latitude || !longitude) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          (error: GeolocationPositionError) => {
            switch (error.code) {
              case error.PERMISSION_DENIED:
                toast.warn(
                  selectedLanguage === "TAMIL"
                    ? "புவிஇருப்பிடத்திற்கான கோரிக்கையை பயனர் நிராகரித்தார்."
                    : "User denied the request for Geolocation."
                );
                break;
              case error.POSITION_UNAVAILABLE:
                toast.warn(
                  selectedLanguage === "TAMIL"
                    ? "இருப்பிடத் தகவல் கிடைக்கவில்லை."
                    : "Location information is unavailable."
                );
                break;
              case error.TIMEOUT:
                toast.warn(
                  selectedLanguage === "TAMIL"
                    ? "பயனர் இருப்பிடத்தைப் பெறுவதற்கான கோரிக்கை காலாவதியானது."
                    : "The request to get user location timed out."
                );
                break;
              default:
                toast.warn(
                  selectedLanguage === "TAMIL"
                    ? "புவி இருப்பிடம் முடக்கப்பட்டது! பின்னர் மீண்டும் முயற்சிக்கவும்."
                    : "Geo Location disabled! Try again later"
                );
            }
            return;
          }
        );
      } else {
        toast.warn(
          selectedLanguage === "TAMIL"
            ? "புவி இருப்பிடம் முடக்கப்பட்டது! பின்னர் மீண்டும் முயற்சிக்கவும்."
            : "Geo Location disabled! Try again later"
        );
        return;
      }
    }
    if (!capturedImages || capturedImages.length < 0 || !note || !branch) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "படங்கள், சுகாதாரத் துறை மற்றும் குறிப்பை வழங்கவும்."
          : "Provide images, sanitary department and note"
      );
      return;
    }
    const ticketForm = new FormData();

    ticketForm.append("branch", branch);
    ticketForm.append("note", note);
    ticketForm.append("latitude", latitude!.toString());
    ticketForm.append("longitude", longitude!.toString());
    capturedImages.map((image) => {
      ticketForm.append("images", dataURItoBlob(image));
    });
    const success = await submitQuery(ticketForm);
    if (success) {
      if (isLoggedIn) {
        navigate(KRoutes.tickets);
      } else {
        navigate(KRoutes.dashboard);
      }
    }
  };

  return (
    <Layout title="query">
      {openCamera ? (
        <div
          className={`w-screen ${
            isMobile ? "h-screen" : "h-[80vh]"
          } flex justify-center items-center`}
        >
          <Camera
            onTakePhoto={(dataURL: string) => {
              setCapturedImages((prevImages) => [...prevImages, dataURL]);
              setOpenCamera(false);
            }}
            imageType={IMAGE_TYPES.JPG}
            // idealResolution={{ width: 640, height: 480 }}
            // imageCompression={0.97}
            // isMaxResolution={true}
            // isImageMirror={false}
            // isSilentMode={false}
            // isDisplayStartCameraError={true}
            isFullscreen={false}
            // sizeFactor={1}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            onCameraError={(error) => {
              console.log("ERROR", error);
            }}
          />
        </div>
      ) : (
        <>
          {isImageViewerOpen && capturedImages.length > 0 && (
            <ImageViewer
              src={capturedImages}
              currentIndex={viewedImageIndex}
              onClose={() => setImageViewerOpen(false)}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}
          {isCropperOpen && capturedImages.length > 0 && (
            <div>
              <Cropper
                image={capturedImages[cropImageIndex]}
                aspect={4 / 3}
                crop={crop}
                zoom={zoom}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(croppedArea, croppedAreaPixels) =>
                  setCroppedAreaPixels(croppedAreaPixels)
                }
              />
              <div
                className="flex items-center justify-center gap-x-6 h-0"
                style={{
                  margin: "auto",
                  position: "absolute",
                  top: "580px",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 11,
                }}
              >
                <button
                  type="button"
                  className="rounded-md bg-danger-color px-3 py-2 text-sm font-semibold text-light-color shadow-sm hover:bg-light-danger-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-light-color"
                  onClick={() => handleCrop("close")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleCrop("crop")}
                  className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-light-color shadow-sm hover:bg-light-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-light-color"
                >
                  Submit
                </button>
              </div>
            </div>
          )}

          <div className="flex w-full h-full min-h-screen justify-center items-center">
            <div className="hidden lg:flex h-full min-h-screen w-1/2 items-center justify-center relative">
              <img alt="image" src={Images.query} width={800} height={800} />
            </div>
            <div className="lg:flex lg:w-1/2 h-full min-h-screen lg:items-center lg:justify-center lg:relative">
              <div className="space-y-12 px-6 mt-10">
                <div className="border-b border-gray-900/10 pb-4 lg:pb-6">
                  <div className="">
                    <p className="text-3xl md:text-4xl font-extrabold uppercase text-center text-secondary-color pb-4 lg:pb-6">
                      {selectedLanguage === "TAMIL"
                        ? QUERY_TITLE_TAMIL
                        : QUERY_TITLE_ENGLISH}
                    </p>

                    <div
                      className="lg:hidden w-1/2 pb-4 lg:pb-6"
                      style={{ margin: "0 auto" }}
                    >
                      <img
                        alt="image"
                        src={Images.query}
                        width={800}
                        height={800}
                      />
                    </div>

                    <p className="text-sm text-justify">
                      {selectedLanguage === "TAMIL"
                        ? QUERY_DESCRIPTION_TAMIL
                        : QUERY_DESCRIPTION_ENGLISH}
                    </p>
                  </div>
                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                      <label
                        htmlFor="photo"
                        className="block text-sm font-medium leading-6 text-prmary-color"
                      >
                        {selectedLanguage === "TAMIL"
                          ? QUERY_IMAGES_TAMIL
                          : QUERY_IMAGES_ENGLISH}
                      </label>
                      <div className="mt-2 flex items-center gap-x-3">
                        {capturedImages &&
                          capturedImages.map((capturedImage, index) => (
                            <li
                              key={index}
                              className={`relative mr-4 list-none ${
                                isCropperOpen && "hidden"
                              }`}
                            >
                              <div className="h-23 w-23 text-gray-300 relative rounded-full">
                                <img
                                  src={capturedImage}
                                  alt={`Captured Image ${index + 1}`}
                                  onClick={() => {
                                    setImageViewerOpen(true);
                                    setViewedImageIndex(index);
                                  }}
                                />
                                <button
                                  className="absolute top-0 right-8 p-1 cursor-pointer text-white bg-secondary-color rounded-full"
                                  onClick={() => {
                                    setCropperOpen(true);
                                    setCropImageIndex(index);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M15 4s1.5-2 2.5-2 2 .5 2 2-1.5 2-1.5 2M17 6L5 18"
                                    />
                                  </svg>
                                </button>
                                <button
                                  className="absolute top-0 right-0 p-1 cursor-pointer text-white bg-red-500 rounded-full"
                                  onClick={() =>
                                    setCapturedImages(
                                      capturedImages.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M6 18L18 6M6 6l12 12"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </li>
                          ))}
                        {capturedImages.length === 0 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-26 w-26 text-gray-300"
                            onClick={handleOpenCamera}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                          </svg>
                        )}
                        {capturedImages.length <= 1 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-26 w-26 text-gray-300"
                            onClick={handleOpenCamera}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                          </svg>
                        )}
                        {capturedImages.length <= 2 && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-26 w-26 text-gray-300"
                            onClick={handleOpenCamera}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="flex justify-center items-center">
                        <button
                          type="button"
                          onClick={handleOpenCamera}
                          className="rounded-md bg-white px-2.5 py-1.5 mt-6 text-sm font-semibold text-prmary-color shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          {selectedLanguage === "TAMIL"
                            ? QUERY_OPEN_CAMERA_TAMIL
                            : QUERY_OPEN_CAMERA_ENGLISH}
                        </button>
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="department"
                        className="block text-sm font-medium leading-6 text-prmary-color"
                      >
                        {selectedLanguage === "TAMIL"
                          ? QUERY_BRANCH_TAMIL
                          : QUERY_BRANCH_ENGLISH}
                      </label>
                      <div className="mt-2">
                        <select
                          id="department"
                          name="department"
                          value={branch}
                          onChange={(e) => setBranch(e.target.value)}
                          className="block w-full rounded-md border-0 px-2 py-1.5 h-10 text-prmary-color shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        >
                          {branches.map((singleBranch) => (
                            <>
                              <option
                                selected
                                key="select-branch"
                                value={undefined}
                              >
                                {selectedLanguage === "TAMIL"
                                  ? "கிளையைத் தேர்ந்தெடுக்கவும்"
                                  : "Select Branch"}
                              </option>
                              <option
                                key={singleBranch._id}
                                value={singleBranch._id}
                              >
                                {singleBranch.name}
                              </option>
                            </>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-span-full">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium leading-6 text-prmary-color"
                      >
                        {selectedLanguage === "TAMIL"
                          ? QUERY_NOTE_TAMIL
                          : QUERY_NOTE_ENGLISH}
                      </label>
                      <div className="mt-2">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="block w-full rounded-md border-0 px-2 py-1.5 text-prmary-color shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          defaultValue={note}
                          onChange={(e) => setNote(e.target.value)}
                          placeholder="Write a few sentences about query."
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center gap-x-6 pb-10">
                  <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-secondary-color"
                    onClick={() => navigate(-1)}
                  >
                    {selectedLanguage === "TAMIL"
                      ? QUERY_CANCEL_TAMIL
                      : QUERY_CANCEL_ENGLISH}
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="rounded-md bg-secondary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {selectedLanguage === "TAMIL"
                      ? QUERY_SUBMIT_TAMIL
                      : QUERY_SUBMIT_ENGLISH}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default ScreenQuery;
