import React from "react";
import { useAppSelector } from "../redux/hooks";
import { HOW_TO_USE_CONTENTS } from "../core/constants";

const {
  HOW_TO_USE_TITLE_ENGLISH,
  HOW_TO_USE_TITLE_TAMIL,
  HOW_TO_USE_SRC_ENGLISH,
  HOW_TO_USE_SRC_TAMIL,
} = HOW_TO_USE_CONTENTS;

const ScreenHowToUse = () => {
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  return (
    <div className="bg-primary-color">
      <div className="min-h-screen flex items-center justify-center">
        <iframe
          className="h-screen w-full"
          src={
            selectedLanguage === "TAMIL"
              ? HOW_TO_USE_SRC_TAMIL
              : HOW_TO_USE_SRC_ENGLISH
          }
          title={
            selectedLanguage === "TAMIL"
              ? HOW_TO_USE_TITLE_ENGLISH
              : HOW_TO_USE_TITLE_ENGLISH
          }
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ScreenHowToUse;
