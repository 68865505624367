import React from "react";
import Images from "../utils/images";
import { useAppSelector } from "../redux/hooks";
import { FOOTER_CONTENTS } from "../core/constants";
import { KRoutes } from "../routers/router";
import { Link } from "react-router-dom";

const {
  FOOTER_PRIVACY_POLICY_ENGLISH,
  FOOTER_PRIVACY_POLICY_TAMIL,
  FOOTER_TERMS_AND_CONDITIONS_ENGLISH,
  FOOTER_TERMS_AND_CONDITIONS_TAMIL,
  FOOTER_HOW_TO_USE_ENGLISH,
  FOOTER_HOW_TO_USE_TAMIL,
  FOOTER_COPYWRITE_ENGLISH,
  FOOTER_COPYWRITE_TAMIL,
} = FOOTER_CONTENTS;

const Footer = () => {
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  return (
    <footer className="w-full items-center justify-between z-50 bottom-0 left-0">
      <div className="bg-primary-color  w-full px-4 py-6 z-10 bottom-0 left-0">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0 h-full">
            <Link
              to="https://www.tn.gov.in"
              target="__blank"
              className="flex items-center justify-center"
            >
              <img
                className="cursor-pointer duration-500 ml-2"
                src={Images.footerLogo}
                alt="Footer Logo"
              />
            </Link>
          </div>
          <div className="flex flex-col items-center justify-center">
            <ul className="text-light-color font-medium flex flex-col">
              <li className="mb-4">
                <Link to={KRoutes.privacyPolicy} className="hover:underline">
                  {selectedLanguage === "TAMIL"
                    ? FOOTER_PRIVACY_POLICY_TAMIL
                    : FOOTER_PRIVACY_POLICY_ENGLISH}
                </Link>
              </li>
              <li className="mb-4">
                <Link
                  to={KRoutes.termsAndConditions}
                  className="hover:underline"
                >
                  {selectedLanguage === "TAMIL"
                    ? FOOTER_TERMS_AND_CONDITIONS_TAMIL
                    : FOOTER_TERMS_AND_CONDITIONS_ENGLISH}
                </Link>
              </li>
              <li>
                <Link to={KRoutes.howToUse} className="hover:underline">
                  {selectedLanguage === "TAMIL"
                    ? FOOTER_HOW_TO_USE_TAMIL
                    : FOOTER_HOW_TO_USE_ENGLISH}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-light-color sm:text-center">
            © 2023{" "}
            <Link to={KRoutes.dashboard} className="hover:underline">
              Eco Innovators™
            </Link>
            .{" "}
            {selectedLanguage === "TAMIL"
              ? FOOTER_COPYWRITE_TAMIL
              : FOOTER_COPYWRITE_ENGLISH}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
