import httpClient from "../core/axios";
import ApiEndpoints from "../core/apiEndpoints";
import { dispatch } from "../redux/store";
import { setErrorMessage, setSuccessMessage } from "../redux/notification";
import {
  changeUserLoading,
  setBranches,
  setUserDetails,
  setUserLoggedIn,
  setUserTickets,
  userLogoutState,
} from "../redux/user";

/**
 * To login a user from server api
 */
export const login = async (
  email: string,
  password: string,
  captchaResponse: string
) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.login, {
      email,
      password,
      captchaResponse,
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(
      setUserLoggedIn({
        token: resp.data.results.token,
        role: resp.data.results.role,
      })
    );
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To register a user
 */
export const register = async (data: {
  name: string;
  email: string;
  phone?: string;
  password: string;
}) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().post(ApiEndpoints.register, data);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(changeUserLoading(false));
  }
};

/**
 * To send a user activation
 */
export const sendActivationLink = async (email: string) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.sendActivation, {
      email,
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(changeUserLoading(false));
  }
};

/**
 * To active a user account
 */
export const activation = async (token: string) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.activationAccount, {
      token,
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(changeUserLoading(false));
  }
};

/**
 * To forget a user password
 */
export const forgetPassword = async (email: string) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.forgetPassword, {
      email,
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(changeUserLoading(false));
  }
};

/**
 * To reset a user password
 */
export const resetPassword = async (token: string, password: string) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.resetPassword, {
      token,
      password,
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(changeUserLoading(false));
  }
};

/**
 * To user from server
 */
export const getUserProfile = async () => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.userProfile);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setUserDetails(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To get branches from server
 */
export const getBranches = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.branches);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setBranches(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To get user tickets from server
 */
export const getUserTickets = async (refresh?: boolean) => {
  try {
    // Set Loading if it is not refresh
    if (!refresh) dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().get(ApiEndpoints.userTickets);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setUserTickets(resp.data.results));
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To update a user profile
 */
export const updateProfile = async (data: {
  name: string;
  email: string;
  phone?: string;
  branch?: string;
  waNotifyStatus: boolean;
}) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.userProfile, data);

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setUserDetails(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To update a user firebase token
 */
export const updateFirebaseToken = async (
  token: string,
  type: "primary" | "secondary"
) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.userUpdateFToken, {
      ftoken: {
        primary: type === "primary" ? token : null,
        secondary: type === "secondary" ? token : null,
      },
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    // dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To change a user password
 */
export const changeUserPassword = async (
  currentPassword: string,
  newPassword: string
) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient().patch(ApiEndpoints.changePassword, {
      currentPassword,
      newPassword,
    });

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(changeUserLoading(false));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To submit a user query
 */
export const submitQuery = async (data: FormData) => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    const resp = await httpClient("multipart/form-data").post(
      ApiEndpoints.ticket,
      data
    );

    // Handle Error
    if (resp.status > 201 || !resp.data.success) {
      throw new Error(resp.data.message);
    }

    // Update to Store
    dispatch(setUserDetails(resp.data.results));
    dispatch(setSuccessMessage(resp.data.message));
    return true;
  } catch (error: any) {
    dispatch(setErrorMessage(error.message));
    dispatch(changeUserLoading(false));
  }
};

/**
 * To delete the user permanently
 */
export const logoutUser = async () => {
  try {
    // Set Loading
    dispatch(changeUserLoading(true));

    // API Fetching
    await httpClient().patch(ApiEndpoints.logout);

    // Update to Store
    dispatch(userLogoutState());
    return true;
  } catch (error: any) {
    // Set Error message to store
    dispatch(setErrorMessage(error.message ?? "Error happended"));
    dispatch(changeUserLoading(false));
  }
};
