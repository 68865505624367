import React, { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useAppSelector } from "../../redux/hooks";
import { KRoutes } from "../../routers/router";
import { register } from "../../services";
import Layout from "../../components/Layout";
import Images from "../../utils/images";
import validator from "validator";

const ScreenRegister = () => {
  const { isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPassValue, setConfirmPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (
      !name ||
      !validator.isAlpha(name, undefined, { ignore: " " }) ||
      !email ||
      !validator.isEmail(email) ||
      !passValue.password ||
      !confirmPassValue.password
    ) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "செல்லுபடியாகும் பெயர், மின்னஞ்சல், கடவுச்சொல் மற்றும் கடவுச்சொல்லை உறுதிப்படுத்தவும்"
          : "Provide valid name, email, password and confirm password"
      );
      return;
    }

    if (passValue.password != confirmPassValue.password) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் மற்றும் உறுதிப்படுத்தல் கடவுச்சொல் ஒன்றல்ல"
          : `Password and Confirm Password are not the same`
      );
      return;
    }

    // Check minimum length
    if (passValue.password.length < 8) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்."
          : `Password must be at least 8 characters long.`
      );
      return;
    }

    // Use validator's isStrongPassword function
    const isStrong = validator.isStrongPassword(passValue.password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });

    // If the password is not strong, return an error message
    if (!isStrong) {
      let errorMessage =
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் வலிமை அளவுகோல்களை பூர்த்தி செய்யவில்லை."
          : "Password does not meet the strength criteria.";

      if (!validator.isLowercase(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு சிறிய எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one lowercase letter.";
      }

      if (!validator.isUppercase(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு பெரிய எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one uppercase letter.";
      }

      if (!/\d/.test(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு எண்ணையாவது சேர்க்கவும்."
            : " Include at least one number.";
      }

      if (!/[!@#$%^&*(),.?":{}|<>]/.test(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு சிறப்பு எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one special character.";
      }
      toast.warn(errorMessage);
      return;
    }

    const success = await register({
      name,
      email,
      phone,
      password: passValue.password,
    });
    if (success) navigate(KRoutes.login);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Layout title="register" auth={true}>
      <div className="flex w-full h-full min-h-[80px]">
        <div className="w-full px-3 lg:py-8 flex items-center justify-center">
          <div className="bg-white px-6 md:px-10 lg:py-10 md:rounded-3xl md:border-2 border-gray-200">
            <h1 className="text-4xl font-semibold">
              {selectedLanguage === "TAMIL" ? "பதிவு" : "Register"} &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              &nbsp; &nbsp;
            </h1>

            <div className="mt-2 lg:mt-6">
              <div>
                <label htmlFor="name" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL" ? "பெயர்" : "Name"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "உங்கள் பெயரை உள்ளிடவும்"
                      : "Enter your name"
                  }
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  autoComplete="OFF"
                />
              </div>

              <div className="mt-2">
                <label htmlFor="email" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL" ? "மின்னஞ்சல்" : "Email"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "மின்னஞ்சலை பதிவுசெய்"
                      : "Enter your email"
                  }
                  id="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="OFF"
                />
              </div>
              {/* <div className="mt-2">
                <label htmlFor="phone" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL"
                    ? "தொலைபேசி (விரும்பினால்)"
                    : "Phone (optional)"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "உங்கள் ஃபோனை உள்ளிடவும்"
                      : "Enter your phone"
                  }
                  id="phone"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  autoComplete="OFF"
                />
              </div> */}
              <div className="mt-2">
                <label htmlFor="password" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL" ? "கடவுச்சொல்" : "Password"}
                </label>
                <div className="relative">
                  <input
                    className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent pr-[30px]"
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் கடவுச்சொல்லை உள்ளிடவும்"
                        : "Enter your password"
                    }
                    id="password"
                    type={passValue.showPassword ? "text" : "password"}
                    value={passValue.password}
                    onChange={(e) =>
                      setPassValue({ ...passValue, password: e.target.value })
                    }
                    autoComplete="OFF"
                  />
                  <span
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() =>
                      setPassValue({
                        ...passValue,
                        showPassword: !passValue.showPassword,
                      })
                    }
                  >
                    {passValue.showPassword ? (
                      <svg
                        className="h-5 text-gray-700"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className="h-5 text-gray-700"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          fill="currentColor"
                          d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                        ></path>
                      </svg>
                    )}
                  </span>
                </div>
              </div>
              <div className="mt-2">
                <label
                  htmlFor="confirm password"
                  className="text-lg font-medium"
                >
                  {selectedLanguage === "TAMIL"
                    ? "கடவுச்சொல்லை உறுதிப்படுத்தவும்"
                    : "Confirm Password"}
                </label>
                <div className="relative">
                  <input
                    className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent pr-[30px]"
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் உறுதிப்படுத்தல் கடவுச்சொல்லை உள்ளிடவும்"
                        : "Enter your confirm password"
                    }
                    id="confirm password"
                    type={confirmPassValue.showPassword ? "text" : "password"}
                    value={confirmPassValue.password}
                    onChange={(e) =>
                      setConfirmPassValue({
                        ...confirmPassValue,
                        password: e.target.value,
                      })
                    }
                    autoComplete="OFF"
                  />
                  <span
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() =>
                      setConfirmPassValue({
                        ...confirmPassValue,
                        showPassword: !confirmPassValue.showPassword,
                      })
                    }
                  >
                    {confirmPassValue.showPassword ? (
                      <svg
                        className="h-5 text-gray-700"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className="h-5 text-gray-700"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          fill="currentColor"
                          d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                        ></path>
                      </svg>
                    )}
                  </span>
                </div>
              </div>

              <div className="mt-6 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  {selectedLanguage === "TAMIL" ? "பதிவு" : "Register"}
                </button>
              </div>
              <div className="mt-1 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 text-base text-primary-color font-medium rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    navigate(KRoutes.login);
                  }}
                >
                  {selectedLanguage === "TAMIL" ? "உள்நுழைய" : "Login"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="hidden lg:flex h-full  min-h-[80px] w-1/2 items-center justify-center  bg-gray-200 relative">
          <img className="w-80 h-80 " src={Images.register} alt="register" />
        </div> */}
      </div>
    </Layout>
  );
};

export default ScreenRegister;
