import React, { MouseEventHandler, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useAppSelector } from "../../redux/hooks";
import { KRoutes } from "../../routers/router";
import { resetPassword } from "../../services";
import Layout from "../../components/Layout";
import Images from "../../utils/images";
import validator from "validator";

const Screenlogin = () => {
  const { isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();
  const params = useParams();

  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });
  const [confirmPassValue, setConfirmPassValue] = useState({
    password: "",
    showPassword: false,
  });

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (
      !params.token ||
      !passValue.password ||
      !confirmPassValue.password ||
      passValue.password != confirmPassValue.password
    ) {
      toast(
        !passValue.password || !confirmPassValue.password
          ? selectedLanguage === "TAMIL"
            ? "கடவுச்சொல்லை வழங்கவும் மற்றும் கடவுச்சொல்லை உறுதிப்படுத்தவும்"
            : `Provide password and confirm password`
          : selectedLanguage === "TAMIL"
          ? "இரண்டு கடவுச்சொல்லும் சமமாக இருக்க வேண்டும்"
          : "Both password must be equal"
      );
      return;
    }

    // Check minimum length
    if (passValue.password.length < 8) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்."
          : `Password must be at least 8 characters long.`
      );
      return;
    }

    // Use validator's isStrongPassword function
    const isStrong = validator.isStrongPassword(passValue.password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });

    // If the password is not strong, return an error message
    if (!isStrong) {
      let errorMessage =
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் வலிமை அளவுகோல்களை பூர்த்தி செய்யவில்லை."
          : "Password does not meet the strength criteria.";

      if (!validator.isLowercase(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு சிறிய எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one lowercase letter.";
      }

      if (!validator.isUppercase(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு பெரிய எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one uppercase letter.";
      }

      if (!/\d/.test(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு எண்ணையாவது சேர்க்கவும்."
            : " Include at least one number.";
      }

      if (!/[!@#$%^&*(),.?":{}|<>]/.test(passValue.password)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு சிறப்பு எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one special character.";
      }
      toast.warn(errorMessage);
      return;
    }

    const success = await resetPassword(params.token, passValue.password);
    if (success) navigate(KRoutes.login);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Layout title="reset-password" auth={true}>
      <div className="flex w-full h-full min-h-[80vh]">
        <div className="w-full px-3 lg:py-8 flex items-center justify-center">
          <div className="bg-white px-6 md:px-10 py-12 md:rounded-3xl md:border-2 border-gray-200">
            <h1 className="text-4xl font-semibold">
              {selectedLanguage === "TAMIL"
                ? "கடவுச்சொல்லை மீட்டமைக்க"
                : "Reset Password"}
            </h1>

            <div className="mt-6">
              <div className="mt-6">
                <label htmlFor="password" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL"
                    ? "புதிய கடவுச்சொல்"
                    : "New Password"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "உங்கள் புதிய கடவுச்சொல்லை உள்ளிடவும்"
                      : "Enter your new password"
                  }
                  id="password"
                  type="password"
                  value={passValue.password}
                  onChange={(e) =>
                    setPassValue({ ...passValue, password: e.target.value })
                  }
                />
              </div>

              <div className="mt-6">
                <label
                  htmlFor="confirm-password"
                  className="text-lg font-medium"
                >
                  {selectedLanguage === "TAMIL"
                    ? "கடவுச்சொல்லை உறுதிப்படுத்தவும்"
                    : "Confirm Password"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "உங்கள் உறுதிப்படுத்தல் கடவுச்சொல்லை உள்ளிடவும்"
                      : "Enter your confirm password"
                  }
                  id="confirm-password"
                  type="password"
                  value={confirmPassValue.password}
                  onChange={(e) =>
                    setConfirmPassValue({
                      ...confirmPassValue,
                      password: e.target.value,
                    })
                  }
                />
              </div>

              <div className="mt-8 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  {selectedLanguage === "TAMIL" ? "மீட்டமை" : "Reset"}
                </button>
              </div>
              <div className="mt-2 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 text-base text-primary-color font-medium rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    navigate(KRoutes.login);
                  }}
                >
                  {selectedLanguage === "TAMIL" ? "உள்நுழையவும்" : "Signin"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="hidden lg:flex h-full w-1/2 items-center justify-center  bg-gray-200 relative">
          <img className="w-80 h-80 " src={Images.resetPassword} alt="login" />
        </div> */}
      </div>
    </Layout>
  );
};

export default Screenlogin;
