import React, { useEffect, useState } from "react";
import { getUserTickets } from "../../services";
import { autoRefreshDelay } from "../../core/constants";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useAppSelector } from "../../redux/hooks";
import ImageViewer from "react-simple-image-viewer";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import ApiEndpoints from "../../core/apiEndpoints";
import Images from "../../utils/images";
import Loader from "../../components/Loader";

const ScreenQuery = () => {
  const { tickets, isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);

  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [viewableImages, setViewableImages] = useState<string[]>([]);
  const [viewedImageIndex, setViewedImageIndex] = useState<number>(0);
  const [respImageEnabledList, setRespImageEnabledList] = useState<string[]>(
    []
  );

  useEffect(() => {
    getUserTickets();
    const refreshInterval = setInterval(
      () => getUserTickets(true),
      autoRefreshDelay * 1000
    );
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  const toggleImageStatus = (id: string) => {
    // Check if the image name is already in the array
    if (respImageEnabledList.includes(id)) {
      // If present, remove it from the array
      setRespImageEnabledList((prevList) =>
        prevList.filter((respImageId) => respImageId !== id)
      );
    } else {
      // If not present, add it to the array
      setRespImageEnabledList((prevList) => [...prevList, id]);
    }
  };

  const formatDateRelativeToToday = (date: string) => {
    const timeDifference = new Date().getTime() - new Date(date).getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (years > 0) {
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else {
      return `${seconds} ${seconds === 1 ? "second" : "seconds"} ago`;
    }
  };

  return (
    <Layout title="tickets">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isImageViewerOpen && viewableImages.length > 0 && (
            <ImageViewer
              src={viewableImages}
              currentIndex={viewedImageIndex}
              onClose={() => setImageViewerOpen(false)}
              disableScroll={false}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
              }}
              closeOnClickOutside={true}
            />
          )}

          <div className="w-full h-full min-h-[80vh] pt-16 pb-10">
            <h2 className="text-xl md:text-4xl uppercase font-extrabold text-center text-secondary-color">
              {selectedLanguage === "TAMIL" ? "டிக்கெட்டுகள்" : "Tickets"}
            </h2>
            <div
              className={`flex ${
                tickets.length <= 0 ? "items-center" : "items-start"
              } justify-center min-h-[80vh]`}
            >
              <div className="p-4">
                {tickets.length <= 0 ? (
                  <div className="flex flex-col items-center justify-center">
                    <img
                      src={Images.nodata}
                      height={80}
                      width={80}
                      alt="empty"
                    />
                    <h1 className="mt-2 text-base text-primary-color font-medium ">
                      {selectedLanguage === "TAMIL"
                        ? "டிக்கெட் இல்லை"
                        : "No Tickets"}
                    </h1>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                    {/* {Array.from({ length: 10 }, (_, index) => ( */}
                    {tickets.map((ticket: any) => (
                      <div
                        key={ticket._id}
                        className="bg-white rounded-lg border p-4"
                      >
                        <Swiper
                          modules={[
                            Navigation,
                            Pagination,
                            Scrollbar,
                            A11y,
                            Autoplay,
                          ]}
                          spaceBetween={20}
                          slidesPerView={1}
                          speed={1200}
                          navigation
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                          autoplay={{ delay: 2000 }}
                        >
                          {(respImageEnabledList.includes(ticket._id)
                            ? ticket.resolvedImages
                            : ticket.images
                          ).map((image: any, i: number) => (
                            <SwiperSlide key={i}>
                              <img
                                alt="ticket image"
                                src={ApiEndpoints.baseImageUrl + "/" + image}
                                className="w-full h-64 rounded-md object-cove"
                                onClick={() => {
                                  setImageViewerOpen(true);
                                  setViewableImages(
                                    (respImageEnabledList.includes(ticket._id)
                                      ? ticket.resolvedImages
                                      : ticket.images
                                    ).map(
                                      (image: string) =>
                                        ApiEndpoints.baseImageUrl + "/" + image
                                    )
                                  );
                                  setViewedImageIndex(i);
                                }}
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>

                        <div className="bg-white">
                          <div className="flex items-center justify-between px-4 pt-4">
                            <div>
                              {ticket.status === "RESOLVED" && (
                                <p className="text-sm text-gray-700 mt-2 text-left">
                                  {selectedLanguage === "TAMIL"
                                    ? "பதில் படம்:"
                                    : "Response Image:"}
                                  <input
                                    className="ml-2"
                                    type="checkbox"
                                    onChange={(e) =>
                                      toggleImageStatus(ticket._id)
                                    }
                                  />
                                </p>
                              )}
                            </div>
                            <div className="bg-yellow-200 py-1.5 px-6 rounded-full">
                              <p
                                className={`text-xs ${
                                  ticket.status === "PENDING"
                                    ? "text-yellow-700"
                                    : ticket.status === "REJECTED"
                                    ? "text-red-700"
                                    : ticket.status === "RESOLVED"
                                    ? "text-green-700"
                                    : ticket.status === "ASSIGNED"
                                    ? "text-blue-700"
                                    : "text-red-700"
                                }`}
                              >
                                {ticket.status}
                              </p>
                            </div>
                            <p className="text-sm text-red">
                              {ticket.rejectedReason}
                            </p>
                          </div>
                          <div className="px-1 py-4">
                            <div className="flex items-center">
                              <div className="font-bold text-xl mb-2">
                                {ticket.code}
                              </div>
                              <p className="text-xs text-gray-700 pl-5">
                                {formatDateRelativeToToday(ticket.createdAt)}
                              </p>
                            </div>
                            <p className="text-base text-gray-700 mt-2 text-left">
                              {ticket.note}
                            </p>
                          </div>
                        </div>
                        <div className="px-1 py-4">
                          <a
                            href={`https://www.google.com/maps?q=${ticket.geoLocation.latitude},${ticket.geoLocation.longitude}`}
                            target="_blank"
                            className="text-primary-color hover:underline"
                          >
                            {selectedLanguage === "TAMIL"
                              ? "இருப்பிடத்தைப் பார்வையிடவும்"
                              : "Visit Location"}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default ScreenQuery;
