import axios from "axios";
import ApiEndpoints from "./apiEndpoints";
import { LocalStorageKeys } from "./localStorageKeys";
import { userLogoutState } from "../redux/user";
import { dispatch } from "../redux/store";

const httpClient = (contentType?: string) => {
  // Create intance
  const instance = axios.create({
    baseURL: ApiEndpoints.baseApiUrl,
    withCredentials: true,
    headers: {
      "Content-Type": contentType ?? "application/json",
    },
    validateStatus: function (status) {
      return status < 501;
    },
  });

  //   Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem(LocalStorageKeys.userToken);
    config.headers!.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  //
  instance.interceptors.response.use(function (config) {
    if (
      config.status === 401 ||
      config.statusText === "Unauthorized" ||
      (!config.data.success && config.data.message === "Unauthorized")
    ) {
      dispatch(userLogoutState());
    }
    return config;
  });

  return instance;
};

export default httpClient;
