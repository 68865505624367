export const APP_NAME = "Eco Innovators";
export const autoRefreshDelay = 20;

const FIREBASE_API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY || "FIREBASE_API_KEY";
const FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "FIREBASE_AUTH_DOMAIN";
const FIREBASE_PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || "FIREBASE_PROJECT_ID";
const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "FIREBASE_STORAGE_BUCKET";
const FIREBASE_SENDER_ID =
  process.env.REACT_APP_FIREBASE_SENDER_ID || "FIREBASE_SENDER_ID";
const FIREBASE_APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID || "FIREBASE_APP_ID";
const FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "FIREBASE_MEASUREMENT_ID";
const FIREBASE_VAPID_KEY =
  process.env.REACT_APP_FIREBASE_VAPID_KEY || "FIREBASE_VAPID_KEY";

export const FIREBASE = {
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_VAPID_KEY,
};

export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY || "";

const DASHBOARD_TITLE_ENGLISH = "Eco Innovators";
const DASHBOARD_TITLE_TAMIL = "சுற்றுச்சூழல் கண்டுபிடிப்பாளர்கள்";
const DASHBOARD_DESCRIPTION_ENGLISH =
  "Welcome to Eco Innovators Dustbin Overflow Issue Query Submission. We are committed to maintaining a clean and sustainable environment, and we understand that sometimes, issues may arise in some areas. This application is designed to streamline the process of reporting and resolving dustbin-related concerns. Your feedback is invaluable in helping us improve our services and contribute to a greener planet.";
const DASHBOARD_DESCRIPTION_TAMIL =
  "சுற்றுச்சூழல் கண்டுபிடிப்பாளர்கள் டஸ்ட்பின் ஓவர்ஃப்ளோ பிரச்சினை வினவல் சமர்ப்பிப்பிற்கு வரவேற்கிறோம். தூய்மையான மற்றும் நிலையான சூழலைப் பேணுவதற்கு நாங்கள் உறுதிபூண்டுள்ளோம், சில சமயங்களில், சில பகுதிகளில் சிக்கல்கள் எழக்கூடும் என்பதை நாங்கள் புரிந்துகொள்கிறோம். இந்தப் பயன்பாடு, டஸ்ட்பின் தொடர்பான கவலைகளைப் புகாரளித்துத் தீர்க்கும் செயல்முறையை ஒழுங்குபடுத்த வடிவமைக்கப்பட்டுள்ளது. எங்கள் சேவைகளை மேம்படுத்தவும் பசுமையான கிரகத்திற்கு பங்களிக்கவும் உதவுவதில் உங்கள் கருத்து விலைமதிப்பற்றது.";
const DASHBOARD_BUTTON_ENGLISH = "Create Query";
const DASHBOARD_BUTTON_TAMIL = "வினவலை உருவாக்கவும்";

export const DASHBOARD_CONTENTS = {
  DASHBOARD_TITLE_ENGLISH,
  DASHBOARD_TITLE_TAMIL,
  DASHBOARD_DESCRIPTION_ENGLISH,
  DASHBOARD_DESCRIPTION_TAMIL,
  DASHBOARD_BUTTON_ENGLISH,
  DASHBOARD_BUTTON_TAMIL,
};

const QUERY_TITLE_ENGLISH = "Eco Innovators Query";
const QUERY_TITLE_TAMIL = "சுற்றுச்சூழல் கண்டுபிடிப்பாளர்கள் வினவு";
const QUERY_DESCRIPTION_ENGLISH =
  "We want to extend our heartfelt thanks for your willingness to volunteer with Eco Innovators. Your commitment to environmental sustainability and your willingness to contribute your time and your energy to our initiatives is truly appreciated.";
const QUERY_DESCRIPTION_TAMIL =
  "Eco Innovators உடன் தன்னார்வத் தொண்டு செய்ய நீங்கள் விரும்பியதற்கு எங்கள் மனமார்ந்த நன்றியைத் தெரிவிக்க விரும்புகிறோம். சுற்றுச்சூழல் நிலைத்தன்மைக்கான உங்கள் அர்ப்பணிப்பு மற்றும் உங்கள் நேரத்தையும் ஆற்றலையும் எங்கள் முயற்சிகளுக்கு பங்களிப்பதற்கான உங்கள் விருப்பம் உண்மையிலேயே பாராட்டத்தக்கது.";
const QUERY_IMAGES_ENGLISH = "images";
const QUERY_IMAGES_TAMIL = "படங்கள்";
const QUERY_OPEN_CAMERA_ENGLISH = "Open Camera";
const QUERY_OPEN_CAMERA_TAMIL = "புகைப்படக்கருவியை திற";
const QUERY_BRANCH_ENGLISH = "Choose your sanitary department";
const QUERY_BRANCH_TAMIL = "உங்கள் சுகாதாரத் துறையைத் தேர்ந்தெடுக்கவும்";
const QUERY_NOTE_ENGLISH = "note";
const QUERY_NOTE_TAMIL = "குறிப்பு";
const QUERY_CANCEL_ENGLISH = "Cancel";
const QUERY_CANCEL_TAMIL = "ரத்து செய்";
const QUERY_SUBMIT_ENGLISH = "Submit";
const QUERY_SUBMIT_TAMIL = "சமர்ப்பிக்க";

export const QUERY_CONTENTS = {
  QUERY_TITLE_ENGLISH,
  QUERY_TITLE_TAMIL,
  QUERY_DESCRIPTION_ENGLISH,
  QUERY_DESCRIPTION_TAMIL,
  QUERY_IMAGES_ENGLISH,
  QUERY_IMAGES_TAMIL,
  QUERY_OPEN_CAMERA_ENGLISH,
  QUERY_OPEN_CAMERA_TAMIL,
  QUERY_BRANCH_ENGLISH,
  QUERY_BRANCH_TAMIL,
  QUERY_NOTE_ENGLISH,
  QUERY_NOTE_TAMIL,
  QUERY_CANCEL_ENGLISH,
  QUERY_CANCEL_TAMIL,
  QUERY_SUBMIT_ENGLISH,
  QUERY_SUBMIT_TAMIL,
};

const FOOTER_PRIVACY_POLICY_ENGLISH = "Privacy Policy";
const FOOTER_PRIVACY_POLICY_TAMIL = "தனியுரிமைக் கொள்கை";
const FOOTER_TERMS_AND_CONDITIONS_ENGLISH = "Terms & Conditions";
const FOOTER_TERMS_AND_CONDITIONS_TAMIL = "விதிமுறைகளும் நிபந்தனைகளும்";
const FOOTER_HOW_TO_USE_ENGLISH = "How to Use";
const FOOTER_HOW_TO_USE_TAMIL = "எப்படி உபயோகிப்பது";
const FOOTER_COPYWRITE_ENGLISH =
  "All Rights Reserved by Kanyakumari Municipality Corporation";
const FOOTER_COPYWRITE_TAMIL =
  "கன்னியாகுமரி நகராட்சியால் அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை";

export const FOOTER_CONTENTS = {
  FOOTER_COPYWRITE_ENGLISH,
  FOOTER_COPYWRITE_TAMIL,
  FOOTER_PRIVACY_POLICY_ENGLISH,
  FOOTER_PRIVACY_POLICY_TAMIL,
  FOOTER_TERMS_AND_CONDITIONS_ENGLISH,
  FOOTER_TERMS_AND_CONDITIONS_TAMIL,
  FOOTER_HOW_TO_USE_ENGLISH,
  FOOTER_HOW_TO_USE_TAMIL,
};

const HOW_TO_USE_TITLE_ENGLISH = "How to use Eco Innovators";
const HOW_TO_USE_TITLE_TAMIL =
  "சுற்றுச்சூழல் கண்டுபிடிப்பாளர்களை எவ்வாறு பயன்படுத்துவது";
const HOW_TO_USE_SRC_ENGLISH = "https://youtu.be/or3rZqGnspQ";
const HOW_TO_USE_SRC_TAMIL = "https://youtu.be/or3rZqGnspQ";

export const HOW_TO_USE_CONTENTS = {
  HOW_TO_USE_TITLE_ENGLISH,
  HOW_TO_USE_TITLE_TAMIL,
  HOW_TO_USE_SRC_ENGLISH,
  HOW_TO_USE_SRC_TAMIL,
};
