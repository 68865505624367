import { Navigate } from "react-router-dom";
import { LocalStorageKeys } from "../core/localStorageKeys";
import { KRoutes } from "./router";

const NonPrivateRoute = ({ component, ...rest }: any) => {
  const adminToken = localStorage.getItem(LocalStorageKeys.userToken);

  return adminToken ? <Navigate to={KRoutes.dashboard} /> : component;
};

export default NonPrivateRoute;
