class Images {
  static Headerlogo: string = "/images/tn_logo.png";
  static footerLogo: string = "/images/govt_logo.png";
  static dashboard: string = "/svgs/Dashboard.svg";
  static login: string = "/svgs/Login.svg";
  static register: string = "/svgs/Register.svg";
  static forgetPassword: string = "/svgs/Forgot_Password.svg";
  static resetPassword: string = "/svgs/Reset_Password.svg";
  static profile: string = "/images/profile.png";
  static query: string = "/images/query.png";
  static nodata: string = "/svgs/no_data.svg";
}

export default Images;
