import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

interface ILayoutProps {
  children: JSX.Element;
  title: string;
  sidebarHide?: boolean;
  auth?: boolean;
}

const Layout = ({ children, auth }: ILayoutProps) => {
  return (
    <div className="w-full h-screen">
      <Navbar auth={auth} />
      <div className="w-full">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
