import React, { MouseEventHandler, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useAppSelector } from "../../redux/hooks";
import { KRoutes } from "../../routers/router";
import { forgetPassword } from "../../services";
import Layout from "../../components/Layout";
import Images from "../../utils/images";

const Screenlogin = () => {
  const { isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.warn(
        selectedLanguage === "TAMIL" ? "மின்னஞ்சலை வழங்கவும்" : `Provide email`
      );
      return;
    }
    const success = await forgetPassword(email);
    if (success) navigate(KRoutes.login);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Layout title="forget-password" auth={true}>
      <div className="flex w-full h-full min-h-[80vh]">
        <div className="w-full px-3 lg:py-8 flex items-center justify-center">
          <div className="bg-white px-6 md:px-10 py-12 md:rounded-3xl md:border-2 border-gray-200">
            <h1 className="text-4xl font-semibold">
              {selectedLanguage === "TAMIL"
                ? "கடவுச்சொல்லை மறந்து"
                : "Forget Password"}
            </h1>

            <div className="mt-6">
              <div>
                <label htmlFor="email" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL" ? "மின்னஞ்சல்" : "Email"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "மின்னஞ்சலை பதிவுசெய்"
                      : "Enter your email"
                  }
                  type="text"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="mt-8 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  {selectedLanguage === "TAMIL" ? "சமர்ப்பிக்கவும்" : "Submit"}
                </button>
              </div>
              <div className="mt-2 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 text-base text-primary-color font-medium rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={() => navigate(KRoutes.login)}
                >
                  {selectedLanguage === "TAMIL" ? "உள்நுழையவும்" : "Signin"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="hidden lg:flex h-full w-1/2 items-center justify-center  bg-gray-200 relative">
          <img
            className="w-80 h-80 "
            src={Images.forgetPassword}
            alt="forget"
          />
        </div> */}
      </div>
    </Layout>
  );
};

export default Screenlogin;
