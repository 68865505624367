import React from "react";
import { useAppSelector } from "../redux/hooks";
import { useNavigate } from "react-router-dom";
import { KRoutes } from "../routers/router";

const Screen404 = () => {
  const navigate = useNavigate();

  const { selectedLanguage } = useAppSelector((state) => state.settings);
  return (
    <div className="bg-primary-color">
      <div className="w-9/12 m-auto py-26 min-h-screen flex items-center justify-center">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8">
            <h1 className="text-9xl font-bold text-primary-color">404</h1>
            <h1 className="text-6xl font-medium py6 lg:py-20">
              {selectedLanguage === "TAMIL"
                ? "அச்சச்சோ! பக்கம் கிடைக்கவில்லை"
                : "oops! Page not found"}
            </h1>
            <p className="text-2xl pb-8 lg:pb-20 px-12 font-medium">
              {selectedLanguage === "TAMIL"
                ? "அச்சச்சோ! நீங்கள் தேடும் பக்கம் இல்லை. இது நகர்த்தப்பட்டிருக்கலாம் அல்லது நீக்கப்பட்டிருக்கலாம்."
                : "Oops! The page you are looking for does not exist. It might have been moved or deleted."}
            </p>
            <button
              className="bg-primary-color text-white font-semibold px-6 py-3 rounded-md mr-6"
              onClick={() => navigate(KRoutes.dashboard)}
            >
              {selectedLanguage === "TAMIL" ? "டாஷ்போர்டு" : "Dashboard"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen404;
