class ApiEndpoints {
  static serverURL: string =
    process.env.REACT_APP_SERVER_URL ||
    "https://ecoinnovators-server.onrender.com";

  static baseApiUrl: string = `${this.serverURL}/api/v1`;
  static baseImageUrl: string = `${this.serverURL}/image`;

  // Admin
  static user: string = "/user";
  static changeAdminStatus: string = `${this.user}/change-status/`;
  static deleteAdmin: string = `${this.user}/delete/`;
  static login: string = `${this.user}/login`;
  static logout: string = `${this.user}/logout`;
  static register: string = `${this.user}/register`;
  static forgetPassword: string = `${this.user}/forget-password`;
  static sendActivation: string = `${this.user}/send-activation`;
  static activationAccount: string = `${this.user}/verify-activation`;
  static resetPassword: string = `${this.user}/reset-password`;
  static changePassword: string = `${this.user}/change-password`;
  static userProfile: string = `${this.user}/profile`;
  static userUpdateFToken: string = `${this.user}/update-ftoken`;

  static branches: string = "/branch/list";

  static ticket: string = `/ticket`;
  static userTickets: string = `${this.ticket}/user`;
}

export default ApiEndpoints;
