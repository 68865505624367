import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorageKeys } from "../core/localStorageKeys";

interface ISettingsState {
  screenSize?: number;
  currentColor: string;
  currentMode: "Dark" | "Light" | "Auto" | string;
  openSidebar: boolean;
  activeMenuId: string;
  selectedLanguage: "ENGLISH" | "TAMIL";
  navbarActions: {
    userProfile: boolean;
    notification: boolean;
  };
  maintanence: boolean;
}

const settingsInitState: ISettingsState = {
  currentColor: "bg-primary-color",
  currentMode: "Light",
  openSidebar: true,
  activeMenuId: "",
  screenSize: undefined,
  selectedLanguage:
    localStorage.getItem(LocalStorageKeys.selectedLang) === "TAMIL"
      ? "TAMIL"
      : "ENGLISH",
  navbarActions: {
    userProfile: false,
    notification: false,
  },
  maintanence: false,
};

const settingsSlice = createSlice({
  name: "Settings",
  initialState: settingsInitState,
  reducers: {
    setMode: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["currentMode"]>
    ) => {
      state.currentMode = action.payload;
      localStorage.setItem(LocalStorageKeys.themeMode, action.payload);
    },
    setOpenSidebar: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["openSidebar"]>
    ) => {
      state.openSidebar = action.payload;
    },
    setActiveMenuId: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["activeMenuId"]>
    ) => {
      state.activeMenuId = action.payload;
    },
    setColor: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["currentColor"]>
    ) => {
      state.currentColor = action.payload;
      localStorage.setItem(LocalStorageKeys.colorMode, action.payload);
    },
    setLanguage: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["selectedLanguage"]>
    ) => {
      state.selectedLanguage = action.payload;
      localStorage.setItem(LocalStorageKeys.selectedLang, action.payload);
    },
    setopenSidebar: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["openSidebar"]>
    ) => {
      state.openSidebar = action.payload;
    },
    setScreenSize: (
      state: ISettingsState,
      action: PayloadAction<ISettingsState["screenSize"]>
    ) => {
      state.screenSize = action.payload;
    },
    setNavbarActions: (
      state: ISettingsState,
      action: PayloadAction<"userProfile" | "notification">
    ) => {
      state.navbarActions = {
        ...state.navbarActions,
        [action.payload]: !state.navbarActions[action.payload],
      };
    },
  },
});

export const {
  setColor,
  setopenSidebar,
  setNavbarActions,
  setMode,
  setScreenSize,
  setActiveMenuId,
  setOpenSidebar,
  setLanguage,
} = settingsSlice.actions;

export default settingsSlice.reducer;
