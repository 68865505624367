import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userLogoutState } from "../redux/user";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setLanguage, setNavbarActions } from "../redux/settings";
import { KRoutes } from "../routers/router";
import Images from "../utils/images";
import { APP_NAME } from "../core/constants";
import { logoutUser } from "../services";
import { IoLanguage } from "react-icons/io5";

const Navbar = ({ auth = false }: { auth?: boolean }) => {
  const { openSidebar, navbarActions, selectedLanguage } = useAppSelector(
    (state) => state.settings
  );
  const { profile, isLoggedIn } = useAppSelector((state) => state.user);
  const [isMobile, setIsMobile] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      // You can adjust the breakpoint based on your needs
      const isMobileDevice = screenWidth <= 768;

      setIsMobile(isMobileDevice);
    };

    // Initial check on mount
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav className="h-20 px-3 w-full bg-primary-color flex items-center justify-between">
      <div
        className="h-full flex items-center justify-start cursor-pointer"
        onClick={() => navigate(KRoutes.dashboard)}
      >
        <img
          className={`cursor-pointer`}
          src={Images.Headerlogo}
          alt="Header Logo"
          width={isMobile ? 40 : 50}
        />
        <h1
          className={`text-white origin-left font-medium ${
            isMobile ? "text-base" : "text-xl"
          } ml-2 cursor-pointer`}
        >
          {APP_NAME.toUpperCase()}
        </h1>
      </div>

      {auth ? (
        <>
          <div className="flex items-center justify-center gap-x-2 h-0 pr-4">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  setLanguage(
                    selectedLanguage === "TAMIL" ? "ENGLISH" : "TAMIL"
                  )
                )
              }
              className={`rounded-md bg-secondary-color ${
                isMobile ? "px-2" : "px-3"
              } py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color`}
            >
              <IoLanguage />
            </button>
            <button
              type="button"
              onClick={() => navigate(KRoutes.dashboard)}
              className={`rounded-md bg-secondary-color ${
                isMobile ? "px-3" : "px-5"
              } py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color`}
            >
              {selectedLanguage === "TAMIL" ? "டாஷ்போர்டு" : "Dashboard"}
            </button>
          </div>
        </>
      ) : isLoggedIn ? (
        <>
          <div className="flex items-center justify-center gap-x-2 h-0 pr-4">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  setLanguage(
                    selectedLanguage === "TAMIL" ? "ENGLISH" : "TAMIL"
                  )
                )
              }
              className={`rounded-md bg-secondary-color ${
                isMobile ? "px-2" : "px-3"
              } py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color`}
            >
              <IoLanguage />
            </button>
            <button
              type="button"
              className="flex mr-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
              id="user-menu-button"
              aria-expanded="false"
              data-dropdown-toggle="dropdown"
              onClick={() => dispatch(setNavbarActions("userProfile"))}
            >
              <span className="sr-only">
                {selectedLanguage === "TAMIL"
                  ? "பயனர் மெனுவைத் திறக்கவும்"
                  : "Open user menu"}
              </span>
              <img
                className="w-8 h-8 rounded-full"
                src={Images.profile}
                alt="User Profile"
              />
            </button>
          </div>
          {!navbarActions.userProfile && (
            <div
              className={` z-50 my-4 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600 absolute top-12 right-0.5`}
              id="dropdown"
            >
              <div className="py-3 px-4">
                <span className="block text-sm text-gray-900 dark:text-white">
                  {profile?.name ?? "Guest"}
                </span>
                <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">
                  {profile?.email ?? "No Mail"}
                </span>
              </div>
              <ul className="py-1" aria-labelledby="dropdown">
                <li className="w-full">
                  <button
                    onClick={() => {
                      navigate(KRoutes.profile);
                      dispatch(setNavbarActions("userProfile"));
                    }}
                    className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                  >
                    {selectedLanguage === "TAMIL" ? "சுயவிவரம்" : "Profile"}
                  </button>
                </li>

                <li className="w-full">
                  <button
                    onClick={() => {
                      navigate(KRoutes.changePassword);
                      dispatch(setNavbarActions("userProfile"));
                    }}
                    className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                  >
                    {selectedLanguage === "TAMIL"
                      ? "கடவுச்சொல்லை மாற்று"
                      : "Change Password"}
                  </button>
                </li>

                <li className="w-full">
                  <button
                    onClick={() => {
                      dispatch(
                        setLanguage(
                          selectedLanguage === "TAMIL" ? "ENGLISH" : "TAMIL"
                        )
                      );
                    }}
                    className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                  >
                    {selectedLanguage === "TAMIL"
                      ? "மொழியை மாற்றவும்"
                      : "Change Language"}
                  </button>
                </li>

                <li className="w-full">
                  <button
                    onClick={() => {
                      navigate(KRoutes.tickets);
                      dispatch(setNavbarActions("userProfile"));
                    }}
                    className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                  >
                    {selectedLanguage === "TAMIL" ? "டிக்கெட்டுகள்" : "Tickets"}
                  </button>
                </li>

                <li className="w-full">
                  <button
                    onClick={() => {
                      logoutUser();
                      dispatch(setNavbarActions("userProfile"));
                    }}
                    className="block w-full text-start py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white cursor-pointer"
                  >
                    {selectedLanguage === "TAMIL" ? "வெளியேறு" : "Sign out"}
                  </button>
                </li>
              </ul>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex items-center justify-center gap-x-2 h-0 pr-4">
            <button
              type="button"
              onClick={() =>
                dispatch(
                  setLanguage(
                    selectedLanguage === "TAMIL" ? "ENGLISH" : "TAMIL"
                  )
                )
              }
              className={`rounded-md bg-secondary-color ${
                isMobile ? "px-2" : "px-3"
              } py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color`}
            >
              <IoLanguage />
            </button>
            <button
              type="button"
              onClick={() => navigate(KRoutes.login)}
              className={`rounded-md bg-secondary-color ${
                isMobile ? "px-4" : "px-6"
              } py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color`}
            >
              {selectedLanguage === "TAMIL" ? "உள்நுழைய" : "Login"}
            </button>
            <button
              type="button"
              onClick={() => navigate(KRoutes.register)}
              className={`rounded-md bg-secondary-color ${
                isMobile ? "px-3" : "px-5"
              } py-2 text-sm font-semibold text-white shadow-sm hover:bg-white hover:text-primary-color focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-color`}
            >
              {selectedLanguage === "TAMIL" ? "பதிவு" : "Register"}
            </button>
          </div>
        </>
      )}
    </nav>
  );
};

export default Navbar;
