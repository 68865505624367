import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import { setNotificationMessage } from "./redux/notification";
import { dispatch } from "./redux/store";

const firebaseConfig = {
  apiKey: "AIzaSyAj7Z8Jtshf0RXSSvyp9DwE1sZ2QGspJ90",
  authDomain: "eco-innovators.firebaseapp.com",
  projectId: "eco-innovators",
  storageBucket: "eco-innovators.appspot.com",
  messagingSenderId: "886678861939",
  appId: "1:886678861939:web:ea853d8b947d84b91cd206",
  measurementId: "G-T2KVGKST10",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = async () => {
  return new Promise<string | null>(async (resolve, reject) => {
    try {
      const token = await getToken(messaging, {
        vapidKey:
          "BH94QdLtMh_m90rzcO0RriGn4C4_aq8N4deD5QeBr8pkhhs_C1pfK-GPgwmd0B9TQPfxOjXjA7OxIhTBzlS8jco",
      });
      resolve(token);
    } catch (error: any) {
      reject({
        success: false,
        message: error.message || "Notification permission denied!",
      });
    }
  });
};

export const onMessageListener = () =>
  new Promise((resolve: (value: MessagePayload) => void) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      dispatch(setNotificationMessage(payload.notification?.body ?? ""));
      resolve(payload);
    });
  });
