import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { useAppSelector } from "../../redux/hooks";
import { KRoutes } from "../../routers/router";
import { getUserProfile, login } from "../../services";
import Layout from "../../components/Layout";
import Images from "../../utils/images";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";
import { CAPTCHA_SITE_KEY } from "../../core/constants";

const Screenlogin = () => {
  const { isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [passValue, setPassValue] = useState({
    password: "",
    showPassword: false,
  });
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState("");

  const handleCaptchaVerify = (response: string | null) => {
    if (response) {
      setIsCaptchaVerified(true);
      setCaptchaResponse(response);
    }
  };

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (!email || !validator.isEmail(email) || !passValue.password) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "மின்னஞ்சல் மற்றும் கடவுச்சொல்லை நிரப்பவும்"
          : "Fill email and password"
      );
      return;
    }
    if (!isCaptchaVerified && !captchaResponse) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "கூகுள் கேப்ட்சாவை சரிபார்க்கவும்"
          : "Verify google captcha"
      );
      return;
    }
    const success = await login(email, passValue.password, captchaResponse);
    if (success) {
      getUserProfile().then().catch();
      navigate(KRoutes.dashboard);
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Layout title="login" auth={true}>
      <div className="flex w-full h-full min-h-[80vh]">
        <div className="w-full px-3 lg:py-8 flex items-center justify-center">
          <div className="bg-white px-6 md:px-10 lg:py-10 md:rounded-3xl md:border-2 border-gray-200">
            <h1 className="text-4xl font-semibold">
              {selectedLanguage === "TAMIL"
                ? "மீண்டும் வருக!"
                : "Welcome back!"}
              &nbsp; &nbsp; &nbsp;
            </h1>
            {/* <p className="font-medium text-lg mt-4">
              {selectedLanguage === "TAMIL"
                ? "மீண்டும் வருக! உங்கள் விவரங்களை உள்ளிடவும்."
                : "Welcome back! Please enter your details."}
            </p> */}
            <div className="mt-6">
              <div>
                <label htmlFor="email" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL" ? "மின்னஞ்சல்" : "Email"}
                </label>
                <input
                  className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent"
                  placeholder={
                    selectedLanguage === "TAMIL"
                      ? "மின்னஞ்சலை பதிவுசெய்"
                      : "Enter your email"
                  }
                  type="text"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-2">
                <label htmlFor="password" className="text-lg font-medium">
                  {selectedLanguage === "TAMIL" ? "கடவுச்சொல்" : "Password"}
                </label>
                <div className="relative">
                  <input
                    className="w-full border-2 border-gray-100 rounded-xl p-4 mt-1 bg-transparent pr-[30px]"
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் கடவுச்சொல்லை உள்ளிடவும்"
                        : "Enter your password"
                    }
                    id="password"
                    type={passValue.showPassword ? "text" : "password"}
                    value={passValue.password}
                    onChange={(e) =>
                      setPassValue({ ...passValue, password: e.target.value })
                    }
                  />
                  <span
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() =>
                      setPassValue({
                        ...passValue,
                        showPassword: !passValue.showPassword,
                      })
                    }
                  >
                    {passValue.showPassword ? (
                      <svg
                        className="h-5 text-gray-700"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path
                          fill="currentColor"
                          d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className="h-5 text-gray-700"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          fill="currentColor"
                          d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                        ></path>
                      </svg>
                    )}
                  </span>
                </div>
              </div>

              <div className="mt-2 mb-4 flex justify-between">
                <Link
                  to={KRoutes.sendActivationRequest}
                  className="ml-2 font-medium text-base"
                >
                  {selectedLanguage === "TAMIL"
                    ? "கணக்கை செயல்படுத்தல் ?"
                    : "Account Activation ?"}
                </Link>
                <Link
                  to={KRoutes.forgetPassword}
                  className="ml-2 font-medium text-base"
                >
                  {selectedLanguage === "TAMIL"
                    ? "கடவுச்சொல்லை மறந்து ?"
                    : "Forget Password ?"}
                </Link>
              </div>
              <ReCAPTCHA
                sitekey={CAPTCHA_SITE_KEY}
                onChange={handleCaptchaVerify}
              />
              <div className="mt-6 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 bg-primary-color text-white  text-lg font-bold rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={handleSubmit}
                >
                  {selectedLanguage === "TAMIL" ? "உள்நுழைக" : "Sign in"}
                </button>
              </div>
              <div className="mt-2 flex flex-col gap-y-4">
                <button
                  className="active:scale-[.98] active:duration-75 hover:scale-[1.01] ease-in-out transition-all py-3 text-base text-primary-color font-medium rounded-xl"
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    navigate(KRoutes.register);
                  }}
                >
                  {selectedLanguage === "TAMIL" ? "பதிவு" : "Register"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="hidden lg:flex h-full w-1/2 items-center justify-center  bg-gray-200 relative">
          <img className="w-80 h-80 " src={Images.login} alt="login" />
        </div> */}
      </div>
    </Layout>
  );
};

export default Screenlogin;
