import { configureStore } from "@reduxjs/toolkit";

import notificationReducer from "./notification";
import userReducer from "./user";
import settingsReducer from "./settings";

const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    settings: settingsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const dispatch = store.dispatch;
export default store;
