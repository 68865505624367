import React from "react";
import validator from "validator";
import Layout from "../components/Layout";
import { useAppSelector } from "../redux/hooks";

const ScreenPrivacyPolicy = () => {
  const { selectedLanguage } = useAppSelector((state) => state.settings);

  return (
    <Layout title="login">
      <div className="min-h-screen w-full py-10 px-10 lg:px-16 pb-16">
        <h1 className="text-4xl font-semibold pb-10 text-primary-color">
          {selectedLanguage === "TAMIL"
            ? "தனியுரிமைக் கொள்கை"
            : "Privacy Policy"}
        </h1>
        <div className="prose">
          <p className="mb-4">
            {selectedLanguage === "TAMIL"
              ? "எங்கள் தனியுரிமைக் கொள்கையின்படி தனிப்பட்ட தகவல்களைச் சேகரித்துப் பயன்படுத்துகிறோம். பயனர் தரவின் பாதுகாப்பை உறுதி செய்வதற்கான நடவடிக்கைகள் உள்ளன."
              : "We collect and use personal information in accordance with our Privacy Policy. Measures are in place to ensure the security of user data."}
          </p>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL"
              ? "சேவை கிடைக்கும் தன்மை"
              : "Service Availability"}
            :
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "பராமரிப்பு அல்லது புதுப்பிப்புகளுக்காக இணையதளம் அவ்வப்போது செயலிழக்க நேரிடலாம்."
                : "The website may experience occasional downtime for maintenance or updates."}
            </li>
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "இடையூறுகளை குறைக்க முயற்சி செய்கிறோம் ஆனால் தடையில்லா சேவைக்கு உத்தரவாதம் அளிக்க மாட்டோம்"
                : "We strive to minimize disruptions but do not guarantee uninterrupted service."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL" ? "பயனர் நடத்தை" : "User Conduct"}:
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "ஹேக்கிங் மற்றும் அங்கீகரிக்கப்படாத அணுகல் உள்ளிட்ட தீங்கு விளைவிக்கும் அல்லது தீங்கிழைக்கும் செயல்களில் ஈடுபடுவதிலிருந்து பயனர்கள் தடைசெய்யப்பட்டுள்ளனர்."
                : "Users are prohibited from engaging in any harmful or malicious activities, including hacking and unauthorized access."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL"
              ? "தகராறு தீர்வு"
              : "Dispute Resolution"}
            :
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "இந்த இணையதளத்தைப் பயன்படுத்துவதால் எழும் ஏதேனும் சர்ச்சைகள் தீர்க்கப்படும்."
                : "Any disputes arising from the use of this website will be resolved."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL"
              ? "பொறுப்பிற்கான வரம்பு"
              : "Limitation of Liability"}
            :
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "இந்த இணையதளத்தைப் பயன்படுத்துவதால் ஏற்படும் நேரடி, மறைமுக அல்லது விளைவான சேதங்களுக்கு கன்னியாகுமரி நகராட்சிக் கழகம் பொறுப்பேற்காது."
                : "Kanyakumari Municipality Corporation is not liable for any direct, indirect, or consequential damages resulting from the use of this website."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL" ? "ஆளும் சட்டம்" : "Governing Law"}:
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "இந்த விதிமுறைகள் சட்டங்களால் நிர்வகிக்கப்படுகின்றன."
                : "These terms are governed by the laws."}
            </li>
          </ul>

          <p className="font-semibold mb-2">
            {selectedLanguage === "TAMIL"
              ? "விதிமுறைகளுக்கான புதுப்பிப்புகள்"
              : "Updates to Terms"}
            :
          </p>
          <ul className="mb-4">
            <li>
              &nbsp; &nbsp; &nbsp; &nbsp; -{" "}
              {selectedLanguage === "TAMIL"
                ? "இந்த விதிமுறைகள் மற்றும் நிபந்தனைகளை எந்த நேரத்திலும் புதுப்பிக்க எங்களுக்கு உரிமை உள்ளது. விதிமுறைகளை தவறாமல் மதிப்பாய்வு செய்வதற்கு பயனர்கள் பொறுப்பு."
                : "We reserve the right to update these terms and conditions at any time. Users are responsible for regularly reviewing the terms."}
            </li>
          </ul>

          <p>
            {selectedLanguage === "TAMIL"
              ? "இந்த இணையதளத்தைப் பயன்படுத்துவதன் மூலம், இந்த விதிமுறைகள் மற்றும் நிபந்தனைகளைப் படித்து, புரிந்துகொண்டு, ஒப்புக்கொண்டீர்கள் என்பதை ஒப்புக்கொள்கிறீர்கள். இந்த விதிமுறைகளில் ஏதேனும் ஒரு பகுதியை நீங்கள் ஏற்கவில்லை என்றால், இணையதளத்தைப் பயன்படுத்துவதைத் தவிர்க்கவும்."
              : "By using this website, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you do not agree with any part of these terms, please refrain from using the website."}
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenPrivacyPolicy;
