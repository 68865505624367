import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocalStorageKeys } from "../core/localStorageKeys";
import { IUser } from "../types/user";
import { ITicket } from "../types/ticket";
import { IBranch } from "../types/branch";

interface IUserState {
  token: string | null;
  profile?: IUser;
  tickets: ITicket[];
  branches: IBranch[];
  isLoading: boolean;
  isLoggedIn: boolean;
}

const userInitState: IUserState = {
  token: localStorage.getItem(LocalStorageKeys.userToken),
  isLoading: false,
  isLoggedIn: localStorage.getItem(LocalStorageKeys.userToken) ? true : false,
  tickets: [],
  branches: [],
};

const userSlice = createSlice({
  name: "user",
  initialState: userInitState,
  reducers: {
    changeUserLoading: (state: IUserState, action: PayloadAction<boolean>) => {
      if (state.isLoading !== action.payload) {
        state.isLoading = action.payload;
      }
    },
    setUserLoggedIn: (
      state: IUserState,
      action: PayloadAction<{ token: string; role: string }>
    ) => {
      if (!state.isLoggedIn) {
        localStorage.setItem(LocalStorageKeys.userToken, action.payload.token);
        state.token = action.payload.token;
        state.isLoggedIn = true;
        state.isLoading = false;
      }
    },
    setUserDetails: (state: IUserState, action: PayloadAction<IUser>) => {
      state.profile = action.payload;
      state.isLoading = false;
    },
    setUserTickets: (state: IUserState, action: PayloadAction<ITicket[]>) => {
      state.tickets = action.payload;
      state.isLoading = false;
    },
    setBranches: (state: IUserState, action: PayloadAction<IBranch[]>) => {
      state.branches = action.payload;
      state.isLoading = false;
    },
    clearUserState: (state: IUserState) => {
      state = userInitState;
    },
    userLogoutState: (state: IUserState) => {
      if (state.isLoggedIn) {
        localStorage.removeItem(LocalStorageKeys.userToken);
        state.token = null;
        state.profile = undefined;
        state.isLoggedIn = false;
        state.isLoading = false;
        state.tickets = [];
      }
    },
  },
});

export const {
  setUserLoggedIn,
  userLogoutState,
  setUserDetails,
  setBranches,
  setUserTickets,
  changeUserLoading,
  clearUserState,
} = userSlice.actions;

export default userSlice.reducer;
