import React, { MouseEventHandler, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { KRoutes } from "../../routers/router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../redux/hooks";
import { IBranch } from "../../types/branch";
import { getBranches, updateProfile } from "../../services";

const ScreenProfile = () => {
  const navigate = useNavigate();

  const { profile, branches, isLoading } = useAppSelector(
    (state) => state.user
  );
  const { selectedLanguage } = useAppSelector((state) => state.settings);
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [branch, setBranch] = useState<string>();

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    if (profile) {
      setName(profile.name);
      setEmail(profile.email);

      if (profile.branch)
        setBranch(
          typeof profile.branch === "string"
            ? profile.branch
            : (profile.branch as IBranch)._id.toString()
        );
    }
  }, [profile]);

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!name || !email) {
      return;
    }
    await updateProfile({
      name,
      email,
      branch,
      waNotifyStatus: false,
    });
  };

  return (
    <Layout title="dashboard">
      <div className="flex w-full h-[80vh] justify-center items-center">
        <div className="space-y-12 pb-10 lg:py-36 px-6 md:px-[12rem] lg:px-[20rem] xl:px-[30rem] 2xl:px-[44rem] w-full">
          <div className="pb-6">
            <div>
              <h2 className="text-xl md:text-4xl uppercase font-extrabold  text-secondary-color text-center">
                {selectedLanguage === "TAMIL" ? "சுயவிவரம்" : "Profile"}
              </h2>
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL" ? "பெயர்" : "Name"}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் பெயரை உள்ளிடவும்"
                        : "Enter your Name"
                    }
                    className="block w-full rounded-md border-0 px-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL" ? "மின்னஞ்சல்" : "Email"}
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "மின்னஞ்சலை பதிவுசெய்"
                        : "Enter your Email"
                    }
                    className="block w-full rounded-md border-0 px-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {/* <div className="sm:col-span-3">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL" ? "தொலைபேசி" : "Phone"}
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் தொலைபேசியை உள்ளிடவும்"
                        : "Enter your Phone"
                    }
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div> */}

              <div className="sm:col-span-3">
                <label
                  htmlFor="branch"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL" ? "கிளை" : "Branch"}
                </label>
                <div className="mt-2">
                  <select
                    id="branch"
                    name="branch"
                    value={branch}
                    onChange={(e) => setBranch(e.target.value)}
                    className="block w-full rounded-md border-0 px-2  py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    {branches.map((singleBranch) => (
                      <option key={singleBranch._id} value={singleBranch._id}>
                        {singleBranch.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-x-6 h-0">
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-prmary-color"
              onClick={() => navigate(-1)}
            >
              {selectedLanguage === "TAMIL" ? "ரத்து செய்" : "Cancel"}
            </button>
            <button
              type="button"
              disabled={isLoading}
              onClick={handleSubmit}
              className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {selectedLanguage === "TAMIL" ? "சமர்ப்பிக்கவும்" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenProfile;
