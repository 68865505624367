import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface INotificationState {
  message: string;
  hasError: boolean;
  hasSuccess: boolean;
  hasWarning: boolean;
  hasNotification: boolean;
}

const notificationInitState: INotificationState = {
  message: "",
  hasError: false,
  hasSuccess: false,
  hasWarning: false,
  hasNotification: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitState,
  reducers: {
    setErrorMessage: (
      state: INotificationState,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
      state.hasError = true;
    },
    resetErrorMessage: (state: INotificationState) => {
      state.message = "";
      state.hasError = false;
    },
    setSuccessMessage: (
      state: INotificationState,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
      state.hasSuccess = true;
    },
    resetSuccessMessage: (state: INotificationState) => {
      state.message = "";
      state.hasSuccess = false;
    },
    setWarningMessage: (
      state: INotificationState,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
      state.hasWarning = true;
    },
    resetWarningMessage: (state: INotificationState) => {
      state.message = "";
      state.hasWarning = false;
    },
    setNotificationMessage: (
      state: INotificationState,
      action: PayloadAction<string>
    ) => {
      state.message = action.payload;
      state.hasNotification = true;
    },
    resetNotificationMessage: (state: INotificationState) => {
      state.message = "";
      state.hasNotification = false;
    },
    resetAllMessage: (state: INotificationState) => {
      state.message = "";
      state.hasNotification = false;
      state.hasError = false;
      state.hasSuccess = false;
      state.hasWarning = false;
    },
  },
});

export const {
  setErrorMessage,
  resetErrorMessage,
  setSuccessMessage,
  resetSuccessMessage,
  setWarningMessage,
  resetWarningMessage,
  setNotificationMessage,
  resetNotificationMessage,
  resetAllMessage
} = notificationSlice.actions;
export default notificationSlice.reducer;
