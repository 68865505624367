import React, { MouseEventHandler, useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { KRoutes } from "../../routers/router";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changeUserPassword } from "../../services";
import { useAppSelector } from "../../redux/hooks";
import validator from "validator";

const ScreenChangePassword = () => {
  const navigate = useNavigate();
  const { isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);

  const [currentPassword, setCurrentPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "தற்போதைய, புதிய மற்றும் உறுதிப்படுத்தும் கடவுச்சொல்லை வழங்கவும்"
          : "Provide current, new and confirm password"
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "புதிய கடவுச்சொல் மற்றும் உறுதிப்படுத்தல் கடவுச்சொல் ஒரே மாதிரியாக இருக்க வேண்டும்"
          : "New Password and Confirm Password must be the same"
      );
      return;
    }

    // Check minimum length
    if (newPassword.length < 8) {
      toast.warn(
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் குறைந்தது 8 எழுத்துக்கள் நீளமாக இருக்க வேண்டும்."
          : `Password must be at least 8 characters long.`
      );
      return;
    }

    // Use validator's isStrongPassword function
    const isStrong = validator.isStrongPassword(newPassword, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 1,
    });

    // If the password is not strong, return an error message
    if (!isStrong) {
      let errorMessage =
        selectedLanguage === "TAMIL"
          ? "கடவுச்சொல் வலிமை அளவுகோல்களை பூர்த்தி செய்யவில்லை."
          : "Password does not meet the strength criteria.";

      if (!validator.isLowercase(newPassword)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு சிறிய எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one lowercase letter.";
      }

      if (!validator.isUppercase(newPassword)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு பெரிய எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one uppercase letter.";
      }

      if (!/\d/.test(newPassword)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு எண்ணையாவது சேர்க்கவும்."
            : " Include at least one number.";
      }

      if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
        errorMessage +=
          selectedLanguage === "TAMIL"
            ? " குறைந்தது ஒரு சிறப்பு எழுத்தையாவது சேர்க்கவும்."
            : " Include at least one special character.";
      }
      toast.warn(errorMessage);
      return;
    }
    await changeUserPassword(currentPassword, newPassword);
  };

  return (
    <Layout title="change-password">
      <div className="flex w-full h-[80vh] justify-center items-center">
        <div className="space-y-12 pb-10 lg:py-36 px-6 md:px-[12rem] lg:px-[20rem] xl:px-[30rem] 2xl:px-[44rem] w-full">
          <div className="border-b border-gray-900/10 pb-6">
            <div className="">
              <h2 className="text-xl md:text-4xl uppercase font-extrabold text-center text-secondary-color">
                {selectedLanguage === "TAMIL"
                  ? "கடவுச்சொல்லை மாற்று"
                  : "Change Password"}
              </h2>
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="currentPassword"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL"
                    ? "தற்போதைய கடவுச்சொல்"
                    : "Current Password"}
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="currentPassword"
                    id="currentPassword"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் தற்போதைய கடவுச்சொல்லை உள்ளிடவும்"
                        : "Enter your Current Password"
                    }
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="new-password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL"
                    ? "புதிய கடவுச்சொல்"
                    : "New Password"}
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="new-password"
                    id="new-password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் புதிய கடவுச்சொல்லை உள்ளிடவும்"
                        : "Enter your New Password"
                    }
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {selectedLanguage === "TAMIL"
                    ? "கடவுச்சொல்லை உறுதிப்படுத்தவும்"
                    : "Confirm Password"}
                </label>
                <div className="mt-2">
                  <input
                    type="password"
                    name="confirm-password"
                    id="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder={
                      selectedLanguage === "TAMIL"
                        ? "உங்கள் உறுதிப்படுத்தல் கடவுச்சொல்லை உள்ளிடவும்"
                        : "Enter your Confirm Password"
                    }
                    className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-x-6 h-0">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="text-sm font-semibold leading-6 text-prmary-color"
            >
              {selectedLanguage === "TAMIL" ? "ரத்து செய்" : "Cancel"}
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isLoading}
              className="rounded-md bg-primary-color px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {selectedLanguage === "TAMIL" ? "சமர்ப்பிக்கவும்" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenChangePassword;
