import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScreenDashboard from "../screens/ScreenDashboard";
import Screen404 from "../screens/Screen404";

import PrivateRoute from "./PrivateRoute";
import Screenlogin from "../screens/auth/ScreenLogin";
import ScreenRegister from "../screens/auth/ScreenRegister";
import ScreenForgetPassword from "../screens/auth/ScreenForgetPassword";
import ScreenResetPassword from "../screens/auth/ScreenResetPassword";
import ScreenQuery from "../screens/ScreenQuery";
import ScreenChangePassword from "../screens/user/ScreenChangePassword";
import ScreenProfile from "../screens/user/ScreenProfile";
import ScreenTickets from "../screens/user/ScreenTickets";
import NonPrivateRoute from "./NonPrivateRoute";
import ScreenTermsAndConditions from "../screens/ScreenTermsAndConditions";
import ScreenPrivacyPolicy from "../screens/ScreenPrivacyPolicy";
import ScreenHowToUse from "../screens/ScreenHowToUse";
import ScreenAccountVerification from "../screens/auth/ScreenAccountVerification";
import ScreenSendActivationRequest from "../screens/auth/ScreenSendActivationRequest";

export const enum KRoutes {
  "dashboard" = "/dashboard",
  "login" = "/login",
  "register" = "/register",
  "accountVerification" = "/account-verification",
  "sendActivationRequest" = "/send-activation-request",
  "forgetPassword" = "/forget-password",
  "resetPassword" = "/reset-password",
  "profile" = "/profile",
  "changePassword" = "/change-password",
  "query" = "/query",
  "tickets" = "/tickets",
  "termsAndConditions" = "/terms-and-conditions",
  "privacyPolicy" = "/privacy-policy",
  "howToUse" = "/how-to-use",
}

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to={KRoutes.dashboard} />} />
          <Route
            path={KRoutes.login}
            element={<NonPrivateRoute component={<Screenlogin />} />}
          />
          <Route
            path={KRoutes.register}
            element={<NonPrivateRoute component={<ScreenRegister />} />}
          />
          <Route
            path={KRoutes.accountVerification + "/:token"}
            element={
              <NonPrivateRoute component={<ScreenAccountVerification />} />
            }
          />
          <Route
            path={KRoutes.sendActivationRequest}
            element={
              <NonPrivateRoute component={<ScreenSendActivationRequest />} />
            }
          />
          <Route
            path={KRoutes.forgetPassword}
            element={<NonPrivateRoute component={<ScreenForgetPassword />} />}
          />
          <Route
            path={KRoutes.resetPassword + "/:token"}
            element={<NonPrivateRoute component={<ScreenResetPassword />} />}
          />

          <Route path={KRoutes.dashboard} element={<ScreenDashboard />} />
          <Route path={KRoutes.query} element={<ScreenQuery />} />

          <Route
            path={KRoutes.profile}
            element={<PrivateRoute component={<ScreenProfile />} />}
          />

          <Route
            path={KRoutes.changePassword}
            element={<PrivateRoute component={<ScreenChangePassword />} />}
          />

          <Route
            path={KRoutes.tickets}
            element={<PrivateRoute component={<ScreenTickets />} />}
          />

          <Route
            path={KRoutes.termsAndConditions}
            element={<ScreenTermsAndConditions />}
          />

          <Route
            path={KRoutes.privacyPolicy}
            element={<ScreenPrivacyPolicy />}
          />

          <Route path={KRoutes.howToUse} element={<ScreenHowToUse />} />

          <Route path="*" element={<Screen404 />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
