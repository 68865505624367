import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { KRoutes } from "../../routers/router";
import Images from "../../utils/images";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { activation } from "../../services";

const ScreenAccountVerification = () => {
  const navigate = useNavigate();
  const params = useParams();

  const { isLoading } = useAppSelector((state) => state.user);
  const { selectedLanguage } = useAppSelector((state) => state.settings);

  const checkToken = async (token: string) => {
    const success = await activation(token);
    if (success) {
      navigate(KRoutes.login);
    }
  };
  useEffect(() => {
    if (params.token) {
      checkToken(params.token);
    }
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <Layout title="login" auth={true}>
      <div className="flex w-full h-full min-h-[80vh]">
        <div className="w-full px-3 lg:py-8 flex items-center justify-center ">
          <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
            <div className="border-t border-gray-200 text-center pt-8">
              <h1 className="text-2xl lg:text-3xl font-medium text-primary-color py-4 ">
                <div className="flex justify-center items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={selectedLanguage === "TAMIL" ? 50 : 30}
                    height={selectedLanguage === "TAMIL" ? 50 : 30}
                  >
                    <circle cx="12" cy="12" r="10" fill="#1DA1F2" />
                    <path
                      d="M8 12l2 2 4-4"
                      stroke="#FFF"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  {selectedLanguage === "TAMIL"
                    ? "அச்சச்சோ! பக்கம் கிடைக்கவில்லை"
                    : "Account Verified"}
                </div>
              </h1>
              <p className="text-xl pb-8 lg:pb-14 py-10 px-4 font-medium">
                {selectedLanguage === "TAMIL"
                  ? "வாழ்த்துகள்! உங்கள் கணக்கு வெற்றிகரமாக சரிபார்க்கப்பட்டது. உங்கள் அடையாளத்தை உறுதிப்படுத்தியதற்கு நன்றி."
                  : "Congratulations! Your account has been successfully verified. Thank you for confirming your identity."}
              </p>
              <button
                className="bg-primary-color text-white font-semibold px-6 py-3 rounded-md mr-6 mb-6"
                onClick={() => navigate(KRoutes.dashboard)}
              >
                {selectedLanguage === "TAMIL" ? "டாஷ்போர்டு" : "Dashboard"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ScreenAccountVerification;
